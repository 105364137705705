import { mapActions, mapGetters } from 'vuex'
import { userContactsChangesTableHead } from '@/modules/users/helpers'

export default {
  name: 'user-students-contacts-changes',
  data () {
    return {
      page: 1,
      showDialog: false,
      updatedItem: null,
      updatedItemType: '',
      message: ''
    }
  },
  watch: {
    showDialog () {
      this.message = ''
    }
  },
  computed: {
    ...mapGetters({
      list: 'userStudents/contactsChangesList',
      isLoading: 'userStudents/isLoading',
      isListLoading: 'userStudents/isListLoading'
    }),
    userContactsChangesTableHead
  },
  created () {
    this.fetchList({ userId: this.$route.params.id })
  },
  methods: {
    ...mapActions({
      fetchList: 'userStudents/GET_CHANGE_CONTACTS',
      update: 'userStudents/UPDATE_CONTACTS'
    }),
    updateContacts () {
      this.update({
        userId: this.$route.params.id,
        id: this.updatedItem.id,
        admin_message: this.message,
        type: this.updatedItemType,
        _method: 'patch'
      }).then(() => {
        this.$toasted.success(this.$t('success_updated'))
        this.showDialog = false
      })
    },
    changeDialog (item, type) {
      this.showDialog = true
      this.updatedItem = item
      this.updatedItemType = type
    }
  },
  destroyed () {
    this.setList([])
  }
}
